import React from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from "react-bootstrap";
import { Icon } from "@mui/material";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import DescriptionIcon from '@mui/icons-material/Description';
import PhoneIcon from '@mui/icons-material/Phone';

const DnyaneshwarMohol = () => {
    return (
        <div class="container profile-box">
            <style>{"\
                header,footer{\
                display:none;\
                }\
            "}</style>
           <div class="cover-image">
               <img src={require("../assets/bloogs-6.jpg")} alt/>
           </div>
                <div class="leftgh">
                    <div class="img-box">
                         <img src={require("../assets/dnyaneshwar-prof.jpg")} alt/>   
                    </div>
                    <div class="name-det">
                        
                   
                     <h2>Dnyaneshwar Mohol</h2>
                     <p>Co-Founder & Director at Virinchi Group</p>
                    <div className="about-box">
                    
                        <ul class="social-link">
                            <li><a data-toggle="modal" data-target="#myModal"><PermIdentityIcon /></a>About</li>
                            <li><a href="https://virinchigroup.in/Swanand_Brochure.pdf" target="_blank"><DescriptionIcon /></a>Brochure</li>
                            <li><a href="tel:+919923895353"><PhoneIcon /></a>Enquiry</li>
                        </ul>
                    </div>
                    <ul class="social-link">
                                        <li><a href="https://virinchigroup.in" target="_blank"><i class="fal fa-globe"></i></a>Website</li>
                                        <li><a href="https://maps.app.goo.gl/eRgPPhpGQrgxvb2b7" target="_blank"><i class="fal fa-map-marker-alt"></i></a>Location</li>
                                        <li><a href="mailto:dnyanesh.mohol@gmail.com"><i class="fal fa-envelope"></i></a>Email</li>
                                    </ul>
                                    <ul class="social-link">
                                        <li><a><i class="fab fa-facebook-f"></i></a>Facebook</li>
                                        <li><a><i class="fab fa-instagram"></i></a>Instagram</li>
                                        <li><a><i class="fab fa-linkedin-in"></i></a>Linked In</li>
                                    </ul>
                                    <div className="address-box">
                    <div className="row">
                        <div className="col-sm-6">
                        <p>Address:</p>
                     <p>14C-II, Ganesh Krupa Society<br/>
                     Lane No 12, Paud Rd,<br/>
                     Paramahansa Nagar, Kothrud<br/>
                        Pune 411038</p>
                     </div>
                     <div className="col-sm-6">
                     <img src={require("../assets/virinchi-group.png")} alt/>  
                     </div>
                        </div>
                        </div>
                        
                      <h3><a href="https://virinchigroup.in" target="_blank">www.virinchigroup.in</a></h3>
                    </div>
                </div>
                <div class="modal fade" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
      
        <div class="modal-header">
          <h4 class="modal-title">About Us</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        
        <div class="modal-body">
            <div>
        <img src={require("../assets/virinchi-group.png")} alt width={100}/> 
        </div>
        Welcome to the ever evolving world of Virinchi Group. Virinchi means Bramha...the creator of the world. Since inception, Virinchi Group has focused on and wholesomely contributed in state-of-the-art constructions. The Group has rich experience of contracting colossal commercial, industrial and residential projects.

Infrastructure development is also one domain wherein Virinchi is a renowned brand across the nation. In all these years and the large number of prestigious projects The Group has accomplished, the key attributes that get prominently reflected are - Excellence, Efficiency & Ecstasy! The Group, on the basis of extensive knowledge and cutting edge expertise in advanced construction technologies, has now ventured into many ambitious lifestyle creations. The community of satisfied customers is growing rapidly.
        </div>
        
      </div>
    </div>
  </div>
  
</div>
    );
}
export default DnyaneshwarMohol;
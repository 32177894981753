import React from "react";
import { Container, Row, Col, Card, Carousel } from "react-bootstrap";

const Portfolio = () => {
  const projects = [
    {
      id: 1,
      name: "Project 1",
      description: "Lorem ipsum dolor sit amet",
      image: "https://via.placeholder.com/150",
      images: [
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
      ],
    },
    {
      id: 2,
      name: "Project 2",
      description: "Consectetur dummy elit",
      image: "https://via.placeholder.com/150",
      images: [
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
      ],
    },
    {
        id: 3,
        name: "Project 3",
        description: "Consectetur adipiscing elit",
        image: "https://via.placeholder.com/150",
        images: [
          "https://via.placeholder.com/300",
          "https://via.placeholder.com/300",
          "https://via.placeholder.com/300",
        ],
      },
  ];

  return (
    <Container>
      <h1>Our Portfolio</h1>
      <Row>
        {projects.map((project) => (
          <Col md={6} lg={4} key={project.id}>
            <Card className="my-3">
              <Card.Img variant="top" src={project.image} />
              <Card.Body>
                <Card.Title>{project.name}</Card.Title>
                <Card.Text>{project.description}</Card.Text>
                <Carousel>
                  {project.images.map((image, index) => (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100"
                        src={image}
                        alt={`Slide ${index}`}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Portfolio;

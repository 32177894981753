import React from 'react';
import { Col, Card } from 'react-bootstrap';

function Cards(props) {
  return (
        <Col className="col-lg-4 col-md-6 col-12">
          <div className='single-service'>
          <Card className="content">
            <img src={props.imageUrl} className="card-img-top" alt="..."></img>
            <Card.Body>
              {/* {props.icon} */}
              <Card.Title><h3>{props.title}</h3></Card.Title>
              <Card.Text><p>{props.description}</p></Card.Text>
            </Card.Body>
          </Card>
          </div>
        </Col>
  );
}

export default Cards;

import React from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, Carousel } from "react-bootstrap";

const Home = () => {
  return (   
    <div>  
    <section className="hero-wrapper section-padding-3">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-md-12 col-lg-6">
                <div className="banner-content">
                    <h2 data-aos="fade-up">Provide Best IT <br/> Services For Your <br/> Business</h2>
                    <Link to={"/contact"} className="theme-btn mt-40" data-aos="fade-up" data-aos-delay="100">Discover More <i className="icon-arrow-right-1"></i></Link>
                </div>
            </div>
            <div className="col-md-12 col-lg-6">
                <div className="banner-img">
                    <img src={require("../assets/img/banner-1.png")}  alt/>
                </div>
            </div>
        </div>
    </div>
    <div className="dots-element">
        <img src={require("../assets/img/home5/dots_01.svg").default}  alt/>
    </div>
    <div className="banner-element ">
        <img src={require("../assets/img/home5/object_01.svg").default}  alt/>
    </div>
    <div className="banner-element-2">
        <img src={require("../assets/img/home5/banner_02.svg").default}  alt/>
    </div>
</section>
<div className="header-gutter"></div> 
    <section className="about-wrapper">
        <div className="container">
            <div className="row position-relative">
                <div className="col-md-12 col-lg-6" data-aos="fade-up" data-aos-delay="100">
                    <div className="about-img">
                        <img src={require("../assets/img/digital-data.png")}  alt/>
                    </div>
                    <div className="img-element">
                        <img src={require("../assets/img/home5/line_dots_01.svg").default}  alt/>
                    </div>
                </div>
                <div className="col-md-12 col-lg-6 mt-30" data-aos="fade-up" data-aos-delay="100">
                    <div className="section-title section__title_3 mb-30">
                        <h6><img src={require("../assets/img/home5/bage.svg").default}  alt/>What we do</h6>
                        <h2>Digital data consulting for your Business Growth</h2>
                    </div>
                    <div className="about-content">
                        <p>DNK Software Pvt Ltd
is dedicated to staying agile and adaptable in order to meet the evolving needs of our clients.</p>
                        <div className="text">
                            <p>We specialize in developing custom software applications</p>
                        </div>
                        <div className="text">
                            <p>help businesses streamline their operations and efficiency</p>
                        </div>
                        <a href="#" className="theme-btn mt-40">Know us more <i className="icon-arrow-right-1"></i></a>
                    </div>
                </div>
            </div>
            </div>
            </section>
            <section className="analytics-wrapper section-padding-3 bg-center bg-cover">
        <div className="container">                                                                               
            <div className="row">
                <div className="col-12 col-xl-6 col-md-6 offset-md-3 text-center">
                    <div className="section-title section__title_3 mb-30" data-aos="fade-up" data-aos-delay="100">
                        <h6><img src={require("../assets/img/home5/bage.svg").default}  alt/>Our Services</h6>
                        <h2>Our Proposition Engineering, Analytics and UI Services</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 mt-30" data-aos="fade-up" data-aos-delay="100">
                    <div className="service-element text-center">
                        <div className="icon">
                            <img src={require("../assets/img/home5/eng_01.png")}  alt/>
                        </div>
                        <h4>Product Engineering</h4>
                        <p>Involves software product development process.</p>
                        <Link to={"/services"} className="theme-btn">Read More <i className="far fa-long-arrow-right"></i></Link>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mt-30" data-aos="fade-up" data-aos-delay="150">
                    <div className="service-element text-center">
                        <div className="icon">
                            <img src={require("../assets/img/home5/eng_02.png")}  alt/>
                        </div>
                        <h4>Data Analytics</h4>
                        <p>Involves the collection, processing, and analysis of large data.</p>
                        <Link to={"/services"} className="theme-btn">Read More <i className="far fa-long-arrow-right"></i></Link>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mt-30" data-aos="fade-up" data-aos-delay="200">
                    <div className="service-element text-center">
                        <div className="icon">
                            <img src={require("../assets/img/home5/eng_03.png")}  alt/>
                        </div>
                        <h4>UX/UI Strategy</h4>
                        <p>Involves intuitive, engaging, and effective digital experiences.</p>
                        <Link to={"/services"} className="theme-btn">Read More <i className="far fa-long-arrow-right"></i></Link>
                    </div>
                </div>
            </div>
        </div>
        
             </section>
       
        <div className="dots-element">
            <img src={require("../assets/img/home5/dots_02.svg").default}  alt/>
        </div>
    
    <section className="prosess-wrapper section-padding-3">
        <div className="container">
            <div className="row">
                <div className="col-12 col-xl-6 col-md-6 offset-md-3 text-center" data-aos="fade-up" data-aos-delay="100">
                    <div className="section-title section__title_3 mb-30" >
                        <h6><img src={require("../assets/img/home5/bage.svg").default}  alt/>Our working process</h6>
                        <h2>We Follow 3 steps to Grow Business</h2>
                    </div>
                </div>
            </div>
            <div className="row" >
                <div className="col-12 col-sm-6 col-lg-3 pt-30">
                    <div className="prosess-element text-center">
                        <div className="icon">
                            <img src={require("../assets/img/home5/icon_01.svg").default}  alt/>
                        </div>
                        <h4>Data Analysis</h4>
                        <p>Gathering data from various sources, process it with analytics and display findings.</p>
                        <span>Step-1</span>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mt-70 pt-30">
                    <div className="prosess-element text-center">
                        <div className="icon">
                            <img src={require("../assets/img/home5/icon_02.svg").default}  alt/>
                        </div>
                        <h4>Make Idea</h4>
                        <p>Create design by checking client needs, target audience with own thought.</p>
                        <span>Step-2</span>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 pt-30">
                    <div className="prosess-element text-center ">
                        <div className="icon">
                            <img src={require("../assets/img/home5/icon_03.svg").default}  alt/>
                        </div>
                        <h4>Develop Product</h4>
                        <p>Collaborating with developers to ensure design implemented correctly as per requirements.</p>
                        <span>Step-3</span>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mt-40 pt-30">
                    <div className="prosess-element text-center">
                        <div className="icon">
                            <img src={require("../assets/img/home5/icon_04.svg").default}  alt/>
                        </div>
                        <h4>Deliver on Time</h4>
                        <p>Launching the product, provide support to ensure that the UX remains effective and engaging.</p>
                        <span>Step-4</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="pro-line text-center">
            <img src={require("../assets/img/home5/bg_shape_03.svg").default}  alt/>
        </div>
    </section>
    <section className="cta-banner style-3">
        <div className="container-fluid bg-cover section-bg" style={{backgroundImage:'../assets/img/cta_bg1.png'}}>
            <div className="cta-content">
                <div className="row align-items-center">
                    <div className="col-xl-7 text-white col-12 text-center text-xl-left">
                        <h1>Ready To Get Free Consulations For <br/> Any Kind Of IT Solutions ? </h1>
                    </div>
                    <div className="col-xl-5 col-12">
                        <div className="btn-wraper d-flex flex-wrap justify-content-xl-end mt-2 mt-md-4 mt-xl-0">
                            <Link to={"/contact"} className="theme-btn">Get a quote <i className="icon-arrow-right-1"></i></Link>
                            <Link to={"/services"} className="theme-btn">read more <i className="icon-arrow-right-1"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> 
</div>
  );
};

export default Home;

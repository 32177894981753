import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import logo from "../assets/img/logo-white.svg";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <footer class="footer-4 footer-wrap">
        <div class="footer-widgets">            
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-md-6 col-xl-3 col-12 pr-xl-4">
                        <div class="single-footer-wid site_footer_widget">
                            <a href="index.html">
                                <img src={logo} alt="DNK Software"/>
                            </a>
                            <p class="mt-4">At DNK Software Pvt Ltd, we're passionate about using technology to solve complex business problems.</p>
                            <div class="social-link mt-30">
                                <a href="#"><i class="fab fa-facebook-f"></i></a>
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-youtube"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-2 col-12">                        
                        <div class="single-footer-wid">
                            <div class="wid-title">
                                <h4>Company</h4>
                            </div>
                            <Nav as="ul">         
                            <Nav.Item as="li"><Nav.Link as={Link} to="/">Home</Nav.Link></Nav.Item>
                            <Nav.Item as="li"><Nav.Link as={Link} to="/about">About</Nav.Link></Nav.Item>
                            <Nav.Item as="li"><Nav.Link as={Link} to="/services">Services</Nav.Link></Nav.Item>
                            <Nav.Item as="li"><Nav.Link as={Link} to="/contact">Contact</Nav.Link></Nav.Item>
                            <Nav.Item as="li"><Nav.Link as={Link} to="/dnyaneshwarmohol">Card</Nav.Link></Nav.Item>
                            </Nav>
                        </div>
                    </div>
                    {/* <div class="col-md-6 col-xl-2 col-12">                        
                        <div class="single-footer-wid">
                            <div class="wid-title">
                                <h4>Useful Link</h4>
                            </div>
                            <ul>
                                <li><a href="about.html">About Us</a></li>
                                <li><a href="about.html">Company History</a></li>
                                <li><a href="contact.html">Need a Career</a></li>
                                <li><a href="project-details.html">Working Process</a></li>
                                <li><a href="news.html">Blog Post</a></li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>

        <div class="footer-bottom">
            <div class="container text-center">
                <div class="footer-bottom-content">
                &copy; {currentYear} <a href="index.html">DNKSoftware</a>. All Rights Reserved
                </div>
            </div>
        </div>
    </footer>
    </footer>
  );
}

export default Footer;
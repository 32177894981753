import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { collection, addDoc } from "firebase/firestore/lite";
import { db } from "./fbaseconfig";
const Contact = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    subject: "",
    message: "",
    submitted: false,
    errors: {
      firstname: "",
      lastname: "",
      email: "",
      subject: "",
      message: "",
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formIsValid()) {
      try {
        await addDoc(collection(db, "contactus"), formData);
        clearForm();
        setFormData((prevState) => ({
          ...prevState,
          submitted: true,
        }));
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const clearForm = () => {
    setFormData({
      firstname: "",
      lastname: "",
      email: "",
      subject: "",
      message: "",
      submitted: false,
      errors: {
        firstname: "",
        lastname: "",
        email: "",
        subject: "",
        message: "",
      },
    });
  };

  const formIsValid = () => {
    const { firstname, lastname, email, subject, message } = formData;
    const errors = {};

    if (!firstname.trim()) {
      errors.firstname = "First name is required";
    }

    if (!lastname.trim()) {
      errors.lastname = "Last name is required";
    }

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
    }

    if (!subject.trim()) {
      errors.subject = "Subject is required";
    }

    if (!message.trim()) {
      errors.message = "Message is required";
    }

    setFormData((prevState) => ({
      ...prevState,
      errors: errors,
    }));

    return Object.values(errors).every((error) => error === "");
  };

  return (
    <>
      <section className="page-banner-wrap bg-cover">
      {/* <div className="banner-text">Contact us</div> */}
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <div className="page-heading text-white">
                <div className="page-title">
                  <h1>Contact Us</h1>
                </div>
              </div>

              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {/* <a href="index.html">Home</a> */}
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Contact Us
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-page-wrap section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-contact-card card1">
                <div className="top-part">
                  <div className="icon">
                    <i className="fal fa-envelope"></i>
                  </div>
                  <div className="title">
                    <h4>Email Address</h4>
                    <span>Sent mail asap anytime</span>
                  </div>
                </div>
                <div className="bottom-part">
                  <div className="info">
                    <p>info@dnksoftware.com</p>
                  </div>
                  <div className="icon">
                    <i className="fal fa-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-contact-card card2">
                <div className="top-part">
                  <div className="icon">
                    <i className="fal fa-phone"></i>
                  </div>
                  <div className="title">
                    <h4>Phone Number</h4>
                    <span>call us asap anytime</span>
                  </div>
                </div>
                <div className="bottom-part">
                  <div className="info">
                    <p>+918975159231</p>
                    <p>+919637539285</p>
                    <p>+919923895353</p>
                  </div>
                  <div className="icon">
                    <i className="fal fa-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-contact-card card3">
                <div className="top-part">
                  <div className="icon">
                    <i className="fal fa-map-marker-alt"></i>
                  </div>
                  <div className="title">
                    <h4>Office Address</h4>
                    <span>Sent mail asap anytime</span>
                  </div>
                </div>
                <div className="bottom-part">
                  <div className="info">
                    <p>DNK Software</p>
                    <p>Hinjawadi Road, Pune 45</p>
                  </div>
                  <div className="icon">
                    <i className="fal fa-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row section-padding pb-0">
            <div className="col-12 text-center mb-20">
              <div className="section-title">
                <p>send us message</p>
                <h2>Say Hello or Message</h2>
              </div>
            </div>

            <div className="col-12 col-lg-12">
              <div className="contact-form">
                {formData.submitted ? (
                  <>
                    {" "}
                    <p>Thank you for your message!</p>
                    <Button
                      variant="primary"
                      onClick={() =>
                        setFormData((prevState) => ({
                          ...prevState,
                          submitted: false,
                        }))
                      }
                    >
                      Send another message
                    </Button>
                  </>
                ) : (
                  <Form
                    noValidate
                    onSubmit={handleSubmit}
                    className="contact-form"
                  >
                    <Row className="mb-3">
                      <Col md="6">
                        <Form.Group controlId="firstname">
                          <Form.Control
                            type="text"
                            name="firstname"
                            placeholder="First name"
                            value={formData.firstname}
                            onChange={handleChange}
                            isInvalid={!!formData.errors.firstname}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formData.errors.firstname}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="lastname">
                          <Form.Control
                            type="text"
                            name="lastname"
                            placeholder="Last name"
                            value={formData.lastname}
                            onChange={handleChange}
                            isInvalid={!!formData.errors.lastname}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formData.errors.lastname}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="6">
                        <Form.Group controlId="email">
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            isInvalid={!!formData.errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formData.errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="subject">
                          <Form.Control
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            value={formData.subject}
                            onChange={handleChange}
                            isInvalid={!!formData.errors.subject}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formData.errors.subject}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                    <Col md="12">
                    <Form.Group controlId="message">
                      <Form.Control
                        as="textarea"
                        name="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                        isInvalid={!!formData.errors.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formData.errors.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    </Row>
                    <Button type="submit" className="theme-btn">
                      Send Message
                    </Button>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cta-banner">
        <div className="container-fluid bg-cover section-bg">
          <div className="cta-content">
            <div className="row align-items-center">
              <div className="col-xl-7 text-white col-12 text-center text-xl-left">
                <h1>
                  Ready To Get Free Consulations For <br /> Any Kind Of It
                  Solutions ?{" "}
                </h1>
              </div>
              <div className="col-xl-5 col-12 text-center text-xl-right">
                <Link to={"/contact"} className="theme-btn mt-4 mt-xl-0">
                  Get a quote
                  <i className="icon-arrow-right-1"></i>
                </Link>
                <Link
                  to={"/services"}
                  className="ml-sm-3 mt-4 mt-xl-0 theme-btn minimal-btn"
                >
                  read more <i className="icon-arrow-right-1"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;

import React from 'react';

function NoPage() {
    return(
        <>
            <div>
            <p>Welcome to NoPage</p>
            </div>
        </>
    );
}

export default NoPage;
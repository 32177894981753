import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import logo from "../assets/img/logo.svg"

function Header() {
  return (
    <header className="header-wrap header-5">
      <div className="top-header d-none d-md-block">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-7">
                        <div className="header-cta">
                            <ul>
                                <li><a href="#"><i className="icon-location-dot"></i>DNK Software, Hinjawadi Road, Pune 411045</a></li>
                                <li><a href="mailto:info@dnksoftware.com"><i className="fal fa-envelope"></i>info@dnksoftware.com</a></li>
                                <li><a href="tel:+918975159231"><i className="fal fa-phone"></i>+918975159231</a></li>
                              </ul>
                        </div>
                    </div>

                    <div className="col-md-5">
                        <div className="header-right-cta d-flex justify-content-end">
                            <div className="social-profile">
                                <a href="#"><i className="fab fa-facebook"></i></a>
                                <a href="#"><i className="fab fa-twitter"></i></a>
                                <a href="#"><i className="fab fa-youtube"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <div bg="light" expand="lg" className='main-header-wraper'>
      <Container fluid>
      <div className="row align-items-center justify-content-between">
        <div className="header-logo">
          <div className="logo">
            <Navbar.Brand href="#home"><img src={logo} alt="logo" height="60"/></Navbar.Brand>
          </div>
        </div>
        
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
        <div className='header-menu d-none d-xl-block'>
          <div className="main-menu">
          <Nav as="ul">         
          <Nav.Item as="li"><Nav.Link as={Link} to="/">Home</Nav.Link></Nav.Item>
          <Nav.Item as="li"><Nav.Link as={Link} to="/about">About</Nav.Link></Nav.Item>
          <Nav.Item as="li"><Nav.Link as={Link} to="/services">Services</Nav.Link></Nav.Item>
          {/* <Nav.Item as="li"><Nav.Link as={Link} to="portfolio">Portfolio</Nav.Link></Nav.Item>
          <Nav.Item as="li"><Nav.Link as={Link} to="/team">Team</Nav.Link></Nav.Item> */}
          <Nav.Item as="li"><Nav.Link as={Link} to="/contact">Contact</Nav.Link></Nav.Item>
          </Nav>
          </div>
        </div>
        <div className="header-right d-flex align-items-center">
        <div className="header-btn-cta">
          <Link to="/contact" className='theme-btn'>Consult Now <i className="icon-arrow-right-1"></i></Link>
          </div>
          <div class="mobile-nav-bar d-block ml-3 ml-sm-5 d-xl-none">
                            <div class="mobile-nav-wrap">                    
                                <div id="hamburger">
                                    <i class="fal fa-bars"></i>
                                </div>
                                <div class="mobile-nav">
                                    <button type="button" class="close-nav">
                                        <i class="fal fa-times-circle"></i>
                                    </button>
                                    <nav class="sidebar-nav">
                                    <Nav as="ul" class="metismenu" id="mobile-menu">         
          <Nav.Item as="li"><Nav.Link as={Link} to="/">Home</Nav.Link></Nav.Item>
          <Nav.Item as="li"><Nav.Link as={Link} to="/about">About</Nav.Link></Nav.Item>
          <Nav.Item as="li"><Nav.Link as={Link} to="/services">Services</Nav.Link></Nav.Item>
          {/* <Nav.Item as="li"><Nav.Link as={Link} to="portfolio">Portfolio</Nav.Link></Nav.Item>
          <Nav.Item as="li"><Nav.Link as={Link} to="/team">Team</Nav.Link></Nav.Item> */}
          <Nav.Item as="li"><Nav.Link as={Link} to="/contact">Contact</Nav.Link></Nav.Item>
          </Nav></nav>
        
                                    <div class="action-bar">
                                        <a href="mailto:info@dnksoftware.com"><i class="fal fa-envelope-open-text"></i>info@dnksoftware.com</a>
                                        <a href="tel:+918975159231"><i class="fal fa-phone"></i>+918975159231</a>
                                        <Link to="/contact" className='d-btn theme-btn black'>Consult Now <i className="icon-arrow-right-1"></i></Link>
                                    </div>
                                </div>                            
                            </div>
                            <div class="overlay"></div>
                        </div>
        </div>
        </div>
      </Container>
    </div>
    <div className="header-gutter"></div> 
    </header>
  );
}

export default Header;
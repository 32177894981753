// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore/lite';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCL65Q2YjwIJ7JPPNrxzaNjYVjTB6kF2tU",
  authDomain: "reactcontactus-1dc5c.firebaseapp.com",
  databaseURL: "https://reactcontactus-1dc5c-default-rtdb.firebaseio.com",
  projectId: "reactcontactus-1dc5c",
  storageBucket: "reactcontactus-1dc5c.appspot.com",
  messagingSenderId: "703151604000",
  appId: "1:703151604000:web:26f5ca44de3418729d5745"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
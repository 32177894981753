import React from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import Cards from './Cards';
import ServicesData  from './ServicesData';
function Services() {
  return (
    <div>
       <section className="page-banner-wrap bg-cover">
        {/* <div className="banner-text">Services</div> */}
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-12 col-12">
                    <div className="page-heading text-white">
                        <div className="page-title">
                            <h1>Services</h1>
                        </div>
                    </div>

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                          <li className="breadcrumb-item active" aria-current="page">Services</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </section>
    <section className="servces-wrapper section-padding">
        <div className="container">
            <div className="row text-center mb-30">
                <div className="col-lg-8 p-lg-0 col-12 offset-lg-2">
                    <div className="section-title">
                        <p>How can help you</p>
                        <h1>We Provide Best IT Solutions For Business</h1>
                    </div>
                    <p className="mt-20">Software enables companies to streamline their operations, improve efficiency, and enhance customer service.</p>
                </div>
            </div>
      <Row>
            {
                ServicesData.map((value,index) => {
                    return <Cards key={index} imageUrl={value.imageUrl} title={value.title} description={value.description} icon={<value.icon size={40} className="text-primary mb-2" />} />
                })
            }
        </Row>
        </div>
        </section>
        <section className="services-wrapper service-1 section-padding section-bg">
        <div className="container">
            <div className="row mb-50">
                <div className="col-12 col-lg-12">
                    <div className="section-title text-white text-center">
                        <p>Popular IT services</p>
                        <h1>Our Professional Solutions <br/>
                            For IT Business</h1>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 col-xl-3 col-12">
                    <div className="single-service-item">
                        <div className="icon">
                            <img src={require("../assets/img/icon/sicon1.png")} alt="service"/>     
                                                  
                        </div>
                        <h4>Manage IT Services</h4>
                        <p>Managing IT services involves the planning, implementation, and maintenance of systems</p>
                        <a href="services-details.html">learn more <i className="fas fa-arrow-right"></i></a>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3 col-12">
                    <div className="single-service-item">
                        <div className="icon">
                            <img src={require("../assets/img/icon/sicon2.png")} alt="service"/>
                        </div>
                        <h4>Product  Engineering</h4>
                        <p>Involves software product development process of designing, developing, and producing a product</p>
                        <a href="services-details.html">learn more <i className="fas fa-arrow-right"></i></a>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3 col-12">
                    <div className="single-service-item">
                        <div className="icon">
                            <img src={require("../assets/img/icon/sicon3.png")} alt="service"/>
                        </div>
                        <h4>Web Development</h4>
                        <p>We specialize in building custom web applications tailored to your business needs</p>
                        <a href="services-details.html">learn more <i className="fas fa-arrow-right"></i></a>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3 col-12">
                    <div className="single-service-item">
                        <div className="icon">
                            <img src={require("../assets/img/icon/sicon4.png")} alt="service"/>
                        </div>
                        <h4>Digital Solutions</h4>
                        <p>Includes technologies, tools, and platforms that enable developers to build web applications</p>
                        <a href="services-details.html">learn more <i className="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="cta-banner">
        <div className="container-fluid bg-cover section-bg">
            <div className="cta-content">
                <div className="row align-items-center">
                    <div className="col-xl-7 text-white col-12 text-center text-xl-left">
                        <h1>Ready To Get Free Consulations For <br/> Any Kind Of It Solutions ? </h1>
                    </div>
                    <div className="col-xl-5 col-12 text-center text-xl-right">
                        <Link to={"/contact"} className="theme-btn mt-4 mt-xl-0">Get a quote 
                        <i className="icon-arrow-right-1"></i></Link>
                            <Link to={"/services"} className="ml-sm-3 mt-4 mt-xl-0 theme-btn minimal-btn">read more <i className="icon-arrow-right-1"></i></Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default Services;

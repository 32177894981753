import React from 'react';
import { Link } from 'react-router-dom';

function About() {
  return (
    <div>
        <section className="page-banner-wrap bg-cover">
        {/* <div className="banner-text">About</div> */}
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-12 col-12">
                    <div className="page-heading text-white">
                        <div className="page-title">
                            <h1>about Us</h1>
                        </div>
                    </div>

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                          <li className="breadcrumb-item active" aria-current="page">about</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </section>

    <section className="about-us-wrapper section-padding">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-12 pr-5">
                    <div className="section-title mb-30">
                        <p>About Company</p>
                        <h1>Get’s IT Solutions From <br/>
                            Expert Consultants</h1>
                    </div>

                    <p className="pr-lg-5">At DNK Software Pvt Ltd, we're passionate about using technology to solve complex business problems. </p>
                    
                    <div className="about-icon-box style-2">
                        <div className="icon">
                            <i className="fal fa-users"></i>
                        </div>
                        <div className="content">
                            <h3>Professinoal Consultants</h3>
                            <p>Our team of experienced developers and consultants bring a unique blend of technical expertise and strategic thinking to every project we undertake.</p>
                        </div>
                    </div>
                    {/* <div className="about-icon-box style-2">
                        <div className="icon">
                            <i className="fal fa-bullseye-arrow"></i>
                        </div>
                        <div className="content">
                            <h3>Managed IT Solutions</h3>
                            <p>Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque</p>
                        </div>
                    </div> */}

                </div>

                <div className="col-lg-6 pl-lg-5 mt-5 mt-lg-0 col-12">
                    <div className="about-right-img">
                        <span className="dot-circle"></span>
                        <div className="about-us-img">
                            <img src={require("../assets/services-1.jpg")}  alt="services" />
                        </div>
                        <span className="triangle-bottom-right"></span>
                    </div>
                </div>
            </div>

            <div className="row mpt-50 pt-100">
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-features-item">
                        <div className="icon">
                            <i className="flaticon-speech-bubble"></i>
                        </div>
                        <div className="content">
                            <h3>IT Consultancy</h3>
                            <p>Faster & Smarter Solutions</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-features-item">
                        <div className="icon">
                            <i className="fal fa-cloud"></i>
                        </div>
                        <div className="content">
                            <h3>Cloud Services</h3>
                            <p>Faster & Smarter Solutions</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-features-item">
                        <div className="icon">
                            <i className="flaticon-mobile-app"></i>
                        </div>
                        <div className="content">
                            <h3>Development</h3>
                            <p>Faster & Smarter Solutions</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>  

    <section className="faq-section section-padding">
        <div className="faq-bg bg-cover d-none d-lg-block">
        <img src={require("../assets/about-1.jpg")}  alt="about" /></div>
        <div className="container">
            <div className="row">
                <div className="col-xl-5 col-lg-6 offset-lg-6 offset-xl-7">
                    <div className="col-12 col-lg-12 mb-40">
                        <div className="section-title">
                            <p>Why choose us</p>
                            <h1>Innovating Solutions <br/> Digital Mindset</h1>
                        </div>
                    </div>
                    <div className="col-12">
                    <p>As a startup, <h5>DNK Software Pvt Ltd</h5> is dedicated to staying agile and adaptable in order to meet the evolving needs of our clients. We specialize in developing custom software applications, web applications, mobile applications, and enterprise solutions that help businesses streamline their operations and improve their efficiency.</p>

<p>At <h5>DNK Software Pvt Ltd</h5>, we believe in providing our clients with personalized service and support throughout the software development process. We work closely with our clients to ensure that their software solutions are tailored to their specific business needs, and we are committed to delivering high-quality products on time and within budget.</p>
</div>
                </div>
            </div>
        </div>
    </section>
    <section className="cta-banner">
        <div className="container-fluid bg-cover section-bg">
            <div className="cta-content">
                <div className="row align-items-center">
                    <div className="col-xl-7 text-white col-12 text-center text-xl-left">
                        <h1>Ready To Get Free Consulations For <br/> Any Kind Of It Solutions ? </h1>
                    </div>
                    <div className="col-xl-5 col-12 text-center text-xl-right">
                        <Link to={"/contact"} className="theme-btn mt-4 mt-xl-0">Get a quote 
                        <i className="icon-arrow-right-1"></i></Link>
                            <Link to={"/services"} className="ml-sm-3 mt-4 mt-xl-0 theme-btn minimal-btn">read more <i className="icon-arrow-right-1"></i></Link>
                    </div>
                </div>
            </div>
        </div>
    </section> 
    </div>
  );
}

export default About;

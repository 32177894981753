import { FaLaptopCode, FaMobileAlt, FaPencilRuler, FaCode, FaIndustry, FaCloud } from 'react-icons/fa';
import service1 from '../assets/services-1.jpg';
import service2 from '../assets/services-2.jpg';
import service3 from '../assets/services-3.jpg';
import service4 from '../assets/services-4.jpg';
import service5 from '../assets/services-5.jpg';
import service6 from '../assets/services-6.jpg';

const ServicesData = [
    {
        imageUrl : service1,
        title: 'Web & Mobile App',
        description : 'We specialize in building custom web applications tailored to your business needs, using the latest technologies like React, Angular, Vu and development practices. We design responsive web and mobile applications with CSS preprocessors that look great and perform well on all screen sizes, while ensuring a user-friendly experience.',
        icon : FaLaptopCode
    },
    {
        imageUrl: service2,
        title: 'UI/UX Design',
        description: 'Our team of designers create beautiful and intuitive user interfaces and experiences that align with your brand and business goals. We conduct user research, design wireframes and prototypes, and perform usability testing to ensure that your users have a seamless and enjoyable experience with your product.',
        icon: FaPencilRuler
    },
    {
        imageUrl: service6,
        title: 'AI in Web',
        description: 'AI significantly impact web development by enabling more personalized and dynamic user experiences, improving website performance and security, and automating various web development tasks with chatbots, Image and voice recognition, optimization, Security, language processing etc.',
        icon : FaMobileAlt
    },
    {
        imageUrl: service4,
        title: 'Enterprise Solutions',
        description: 'We provide enterprise solutions that help businesses manage their operations, improve their workflow, and increase their productivity. Our enterprise solutions include customer relationship management (CRM), enterprise resource planning (ERP), and Mangement systems.',
        icon: FaIndustry
    },
    {
        imageUrl: service5,
        title: 'Custom Software Development',
        description: 'Create custom software solutions tailored to the needs of your clients. This could include web applications, mobile apps, and desktop software.',
        icon: FaCode
    },
    {
        imageUrl: service3,
        title:'Cloud Computing',
        description: 'We provide cloud-based services that help businesses store and manage their data in a secure, scalable, and cost-effective way. Our cloud services include cloud hosting, data migration, and cloud-based application development',
        icon: FaCloud
    }, 
]

export default ServicesData;
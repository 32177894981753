import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import Team from './components/Team';
import Contact from './components/Contact';
import NoPage from './components/NoPage';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Aos from "aos";
import DnyaneshwarMohol from "./components/DnyaneshwarMohol";

function App() {

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            {/* <Route index element={<Home />} /> */}
            <Route exact path="home" element={<Home />} />
            <Route exact path="about" element={<About />} />
            <Route exact path="services" element={<Services />} />
            <Route exact path="portfolio" element={<Portfolio />} />
            <Route exact path="team" element={<Team />} />
            <Route exact path="contact" element={<Contact />} />
            <Route exact path="dnyaneshwarmohol" element={<DnyaneshwarMohol/>} />
            <Route exact path="*" element={<NoPage />} />
          </Routes>
      </div>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import { Container, Row, Col, Card, Carousel } from "react-bootstrap";

const Team = () => {
  const teamMembers = [
    {
      id: 1,
      name: "John Doe",
      position: "CEO",
      bio: "Lorem ipsum dolor sit amet",
      photo: "https://via.placeholder.com/150",
      photos: [
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
      ],
    },
    {
      id: 2,
      name: "Jane Smith",
      position: "CTO",
      bio: "Consectetur adipiscing elit",
      photo: "https://via.placeholder.com/150",
      photos: [
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
      ],
    },
    {
        id: 3,
        name: "Kane Smith",
        position: "CTO",
        bio: "Consectetur adipiscing elit",
        photo: "https://via.placeholder.com/150",
        photos: [
          "https://via.placeholder.com/300",
          "https://via.placeholder.com/300",
          "https://via.placeholder.com/300",
        ],
      },
  ];

  return (
    <Container>
      <h1>Our Team</h1>
      <Row>
        {teamMembers.map((teamMember) => (
          <Col md={6} lg={4} key={teamMember.id}>
            <Card className="my-3">
              <Card.Img variant="top" src={teamMember.photo} />
              <Card.Body>
                <Card.Title>{teamMember.name}</Card.Title>
                <Card.Subtitle>{teamMember.position}</Card.Subtitle>
                <Card.Text>{teamMember.bio}</Card.Text>
                <Carousel>
                  {teamMember.photos.map((photo, index) => (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100"
                        src={photo}
                        alt={`Slide ${index}`}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Team;
